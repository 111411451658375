@import '~styles/variables';
@import '~bulma/sass/utilities/_all';

.levelItem {
  background-color: var(--background-3);
  border-top: 2px solid var(--primary);
  border-radius: 3px;
  margin: 1rem 0;
  padding: 1rem;

  @include tablet {
    margin: 1rem 2rem 1rem 0;
  }
}