@import '~styles/variables';
@import '~bulma/sass/utilities/_all';

.analyticsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0;

  @include tablet {
    margin: 0;
    width: auto;
  }
}

.item {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
}

.itemName {
  font-size: 0.65rem;
}

.itemData {
  font-size: 0.65rem;

  @media screen and (min-width: 1100px) {
    font-size: 0.85rem;
  }
}

.baseCurrency,
.quoteCurrency {
  font-size: 0.85rem;
}

.dropDownTable {
  min-width: 100%;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  font-size: 12px;
  right: 0;

  @include tablet {
    min-width: 600px;
    max-height: 50vh;
  }
}

.dropdown {
  @include touch {
    position: unset;
  }

  > div + div {
    @include touch {
      width: 100%;
    }
  }

  button {
    background-color: var(--background-4) !important;
    color: var(--defaultTextColor) !important;
  }

  > div + div > div {
    background-color: var(--background-4) !important;
    padding: 1rem !important;
    color: var(--defaultTextColor) !important;

    > div {
      color: var(--defaultTextColor) !important;
    }
  }
}
