@import '~styles/variables';

.tabs {
  background-color: $tabs-background-color;
}

.is-divider {
  margin: 0.75rem 0;
}

@media screen and (max-width: 450px) {
  .section {
    padding: 3rem 0.75rem;
  }

  .column {
    padding: 0.75rem 0.35rem;
    text-align: center;
  }
}

.footer {
  padding: 1.5rem;
}

a {
  color: var(--primary);
}

.button {
  &.is-primary {
    background-color: var(--primary);

    &:hover {
      background-color: var(--primary);
    }
  }
}

.is-loading {
  color: transparent!important;
  pointer-events: none;

  &:after {
    position: absolute;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
    position: absolute!important;
    -webkit-animation: spinAround .5s infinite linear;
    animation: spinAround .5s infinite linear;
    border: 2px solid #dbdbdb;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1em;
    position: relative;
    width: 1em;
  }
}

.navbar-burger, .navbar-item, .navbar-link, .title, .dropdown-item, strong {
  color: unset;
}

.navbar {
  z-index: 8;
}