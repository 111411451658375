@import '~styles/variables';

.modal {
  text-align: center;
  position: relative;
  border-top: 25px solid $orange;

  &:before {
    content: '!';
    display: block;
    position: absolute;
    top: -20%;
    left: 3%;
  }
}

.padlock {
  width: 5rem;
}

.exclamationMark {
  fill: var(--primary);
  width: 2.5rem;
  height: 2.5rem;
}
