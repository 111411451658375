.checked {
  height: 20px;
  min-width: 20px;
  fill: var(--primary);
}

.link {
  cursor: pointer;
  color: var(--primary);
  text-decoration: none;
  background-color: transparent;
  border: none;
  font-size: 17px;
  line-height: 20px;
}

.boxLink {
  display: flex;
  align-items: center;
}

.link:hover {
  // color: var(--defaultTextColor);
}
