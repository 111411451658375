@import '~styles/variables';
@import '~bulma/sass/utilities/_all';
  
  .modifiedBtnAddon {
    border-radius: 3px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    height: 41px !important;
  }
  
  .modifiedTextField {
    border-top-right-radius: 0px  !important;
      border-bottom-right-radius: 0px !important;
  }

  .btnTxtBox {
    flex-direction: column !important;
    align-items: inherit;
  }

  @media screen and (max-width: 450px) {
    .btnTxtBox {
      flex-direction: column !important;
      align-items: inherit;
    }
  }