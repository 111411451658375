@import '~styles/variables';
@import '~bulma/sass/utilities/_all';

// .columnsWrap {
//   padding-top: 5px;
//   flex-direction: column !important;
//   flex-wrap: nowrap !important;
//   font-size: 0.65rem;

//   >div:first-of-type+div {
//     display: none;
//   }

//   @media screen and (min-width: 1240px) {
//     flex-direction: row !important;

//     >div:first-of-type+div {
//       display: block;
//       padding: 0;
//     }
//   }

//   @media screen and (min-width: 1440px) {
//     flex-direction: row !important;

//     >div:first-of-type+div {
//       display: block;
//       padding: 0.25rem 2rem 2rem;
//     }
//   }

//   @media screen and (min-width: 2000px) {
//     height: 100%;
//   }

//   .formWrap {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;

//     @media screen and (min-width: 2000px) {
//       height: 100%;
//     }
//   }

//   .selectField {
//     margin: 15px 0 10px;

//     @include fullhd {
//       margin: 15px 0 0;
//     }
//   }

//   .field {
//     margin-bottom: 6px;
//     align-content: center;

//     .label {
//       height: 100%;
//       min-width: 55px;
//       display: flex;
//       align-items: center;
//       color: var(--defaultTextColor);
//       text-align: left;
//     }

//     .input {
//       display: flex;
//       width: 100%;
//     }

//     .controlFirst {
//       flex: 1;

//       input {
//         background-color: transparent;
//         color: var(--defaultTextColor);
//         border-color: var(--background-4);
//       }
//     }

//     .controlSecond {
//       background-color: var(--background-4);
//       color: var(--defaultTextColor);
//       border: 0;
//     }

//     @media screen and (min-width: 1240px) and (max-width: 1600px) {
//       margin-bottom: 0;
//     }
//   }

//   >div {
//     @media screen and (min-width: 1240px) and (max-width: 1600px) {
//       padding-top: 0.25rem;
//     }
//   }

//   .divider {
//     margin: 0.75em 0 1.25em;
//   }

//   .orderFee {
//     padding-bottom: 10px;
//   }

//   .button {
//     margin-top: 10px;
//   }

//   .buyButton {
//     margin-top: 10px;

//     @include fullhd {
//       margin: 20px 0 10px 0;
//     }
//   }

//   .sellButton {
//     margin-top: 10px;

//     @include fullhd {
//       margin: 20px 0 10px 0;
//     }
//   }

//   .signedOutButton {
//     background-color: var(--background-4);
//     color: var(--defaultTextColor);
//     margin: 1rem 0;
//     padding: 1.05rem 0;
//     font-size: 0.85rem !important;

//     * {
//       margin: 0 0.5rem;
//     }
//   }

//   .levelContainer {
//      margin: 0.5rem 0 0;
//      justify-content: space-between;

//     .levelItem {
//       @include tablet {
//         &:not(:last-child) {
//           margin-right: 1rem;
//         }
//       }

//       .percentButtons {
//         color: unset;
//         width: 100%;
//         background-color: var(--background-4);
//       }
//     }
//   }
// }

.pointer{
    cursor: pointer;
}

.hoverDropBtn {
    position: relative;

    .btnlink {
        cursor: pointer;
    }
}

.hoverDrop {
    position: absolute;
    background-color: var(--background-3);
    top: 0px;
    margin-top: 24px;
    right: 0;
    margin-right: -50px;
    min-width: 100px !important;
    z-index: 99;
    border: 1px solid var(--border-1);
    box-shadow: 0px 2px 4px -2px var(--border-1);

    .hoverDropList li {
        padding: 5px 10px;
        cursor: pointer;
    }

    .hoverDropList .active {
        color: var(--brand);
    }

    .hoverDropList .active:hover {
        color: var(--white);
    }

    .hoverDropList li:hover {
        background-color: var(--border-1);
    }
}